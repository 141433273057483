import styled from 'styled-components'

const SectionTitle = styled.div`
  border-bottom: 1px solid black;
  padding: 30px 0 20px 0;
  margin: 0 0 50px 0;
  width: 100%;

  span {
    text-align: left;
    font: Bold 20px/24px Vremena Grotesk;
    letter-spacing: -0.5px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
`

export default SectionTitle
