import React from "react";
import styled from "styled-components";

import Checkbox from "./checkbox";
import { PrimaryButton } from "./button";

const textareaPlaceholder = `Hello, Guys!

We have this amazing idea for an app but we have no clue where
to start. Are you available for a short talk?`;

const gdprAggrement = `I agree to allow the owner of the website store my submitted information so they can respond to my inquiry and I understand that in order to have my data removed, I need to send another message and ask the owner of the website to remove it.`;

const EmailForm = () => {
  return (
    <EmailFormContainer>
      {
        <Form
          name="contactForm"
          data-netlify="true"
          data-netlify-honeypot="honeybunny"
        >
          <input type="hidden" name="form-name" value="contactForm" />
          <Hidden>
            <label>
              Don’t fill this out if you're human: <input name="honeybunny" />
            </label>
          </Hidden>
          <FieldContainer>
            <OneLineInputField
              type="text"
              name="name"
              placeholder="Name"
              required
              aria-label="Name"
            />
            <OneLineInputField
              type="email"
              name="email"
              placeholder="email@mydomain.com"
              required
              aria-label="Email"
            />
            <MultipleLinesInputField
              id="messageBody"
              name="messageBody"
              placeholder={textareaPlaceholder}
              required
              aria-label="Your message"
            />
            <Checkbox required text={gdprAggrement} />
          </FieldContainer>
          <ButtonsContainer>
            <PrimaryButton
              label="Send →"
              color="white"
              bordercolor="white"
              type="submit"
            />
          </ButtonsContainer>
        </Form>
      }
    </EmailFormContainer>
  );
};

export default EmailForm;

const EmailFormContainer = styled.div`
  min-width: inherit;
`;

const Form = styled.form`
  max-width: inherit;
`;
const FieldContainer = styled.div`
  max-width: inherit;
  border: 0px;
  margin: 0;
`;

const OneLineInputField = styled.input`
  width: 100%;
  height: 50.82px;
  background: #fcfcfc;
  border: 1px solid #f4f4ed;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 20px;
  padding: 12px 20px;
  border-radius: 0;
  -webkit-appearance: none;

  text-align: left;
  font: Regular 14px/19px Noah;
  letter-spacing: -0.35px;
  color: #000000;
  opacity: 1;
`;

const MultipleLinesInputField = styled.textarea`
  width: 100%;
  height: 180px;
  background: #fcfcfc;
  border: 1px solid #f4f4ed;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 20px;
  padding: 12px 20px;
  resize: none;
  border-radius: 0;
  -webkit-appearance: none;

  text-align: left;
  font: Regular 14px/19px Noah;
  letter-spacing: -0.35px;
  color: #000000;
  opacity: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Hidden = styled.p`
  display: none;
`;