import React, { useState } from "react"
import styled from 'styled-components'

import Up from '../assets/icons/up.svg'
import Down from '../assets/icons/down.svg'
import Quote from '../assets/icons/quote.svg'
import Right from '../assets/icons/right.svg'
import { SecondaryOutLink } from '../components/button';

const data = [
  {
    name: "Emanuele Musa",
    company: "Babele",
    url: "https://babele.co/home/",
    photo: null,
    what:
      "Appes was successful and user retention increased. Internal stakeholders are pleased with the tool. The project management, adaptability, and hands-on approach were all impressive. They were very effective in coordinating with Babele's team.",
  },
  {
    name: "Corin Moss",
    company: "Infomoss",
    url: "https://infomoss.com",
    photo: null,
    what:
      "One of those rare teams who can look past the code, and understand the core problem that needs to be solved. This, together with their truly excellent self-discipline, creative problem-solving skills, and an excellent grasp of programming concepts makes them very, very strong performers!",
  },
];

const Testimonials = () => {
  const [index, setIndex] = useState(0);

  // setInterval(() => showNext(), 10000)

  const showPrev = () => {
    if (index === 0)
      setIndex(data.length - 1)
    else setIndex(index - 1);
  }

  const showNext = () => {
    if (index === data.length - 1)
      setIndex(0)
    else setIndex(index + 1);
  }

  return(
    <TestimonialContainer>
      <UpArrow onClick={showPrev} />
      <TestimonialCard>
        <WhoContainer>
          <p>{data[index].name}</p>
          <SecondaryOutLink
            label={`${data[index].company} ↗︎`}
            to={data[index].url}
          />
        </WhoContainer>
        <WhatContainer>
          <Quote style={{ width: 50, height: 43, marginBottom: 15 }} />
          <p>{data[index].what}</p>
        </WhatContainer>
      </TestimonialCard>
      <DownArrow onClick={showNext} />
      <RightArrow onClick={showNext} />
    </TestimonialContainer>
  )
}

const UpArrow = styled(Up)`
  width: 45px;
  height: 30px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }
`

const DownArrow = styled(Down)`
  width: 45px;
  height: 30px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }
`

const RightArrow = styled(Right)`
  display: none;
  width: 45px;
  height: 30px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: block;
  }
`

const TestimonialContainer = styled.div`
  max-width: 490px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 575px;
  justify-content: space-between;

  @media (max-width: 767px) {
    min-height: 300px;
  }
`;

const TestimonialCard = styled.div`
  background: #F2F2F2 0% 0% no-repeat padding-box;
  opacity: 1;
  max-width: 500px;
  min-height: 430px;
  display: flex;
  flex-direction: row;
  padding: 40px 30px;

  @media (max-width: 1336px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    background: #FFFFFF;
    flex-direction: column;
    padding: 0;
    min-height: 300px;
  }
`

const WhoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  p {
    margin: 0;
    text-align: left;
    font: Bold 22px/22px Vremena Grotesk;
    letter-spacing: -0.55px;
    color: #000000;
  }

  a {
    text-align: left;
    text-decoration: underline;
    font: Bold 18px/22px Vremena Grotesk;
    letter-spacing: -0.45px;
    color: #000000;
  }

  @media (max-width: 767px) {
    flex: 0;
    p {
      margin: 30px 0 0 0;
    }
  }
`

const WhatContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;

  p {
    text-align: left;
    font-weight: Regular;
    font-family: Noah;
    line-height: 24px;
    font-size: 15px;
    letter-spacing: -0.38px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 767px) {
    flex: 0;
  }
`
export default Testimonials;