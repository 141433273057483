import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const PrimaryButton = ({ width, color, label, bordercolor, type, onClick }) => {
  return (
    <BorderButton style={{ width }} color={color} bordercolor={bordercolor} type={type} onClick={onClick} aria-label={label}>
      {label}
    </BorderButton>
  )
}

const BorderButton = styled.button`
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 50px;
  padding-top: 5px;
  padding-left: 20px;
  color: ${props => props.color};
  border: ${props => `1px solid ${props.bordercolor}`};
  background: transparent;
  font-weight: bold;
  font-family: Vremena Grotesk;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-decoration: none;
  margin: 20px 0;

  :hover {
    text-decoration: ${props => `underline ${props.color}`};
  }


  @media (max-width: 767px) {
    min-width: 100%;
  }
`

PrimaryButton.defaultProps = {
  color: 'black',
  bordercolor: '#E23C30',
}

const PrimaryLink = ({ width, color, label, bordercolor, to, type, background }) => {
  const ariaLabel= `Link to ${to.replace(/\/|#/g, '')}`
  return (
    <BorderLink to={to} style={{ width }} color={color} bordercolor={bordercolor} type={type} background={background} aria-label={ariaLabel}>
      {label}
    </BorderLink>
  )
}

const BorderLink = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 50px;
  padding-top: 5px;
  padding-left: 20px;
  color: ${props => props.color};
  border: ${props => `1px solid ${props.bordercolor}`};
  background: ${props => props.background};;
  font-weight: bold;
  font-family: Vremena Grotesk;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-decoration: none;
  margin: 20px 0;

  :hover {
    text-decoration: ${props => `underline ${props.color}`};
  }

  @media (max-width: 767px) {
    min-width: 100%;
  }
`

PrimaryLink.defaultProps = {
  color: 'black',
  bordercolor: '#E23C30',
  background: 'transparent'
}

const PrimaryDownloadLink = (props) => {
  const ariaLabel= `Link to ${props.href.replace(/\/|#/g, '')}`
  return (
    <BorderDownloadLink href={props.href} download aria-label={ariaLabel} style={{ width: props.width }} color={props.color} bordercolor={props.bordercolor}>
      {props.label}
    </BorderDownloadLink>
  )
}

const BorderDownloadLink = styled.a`
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 50px;
  padding-top: 5px;
  padding-left: 20px;
  color: ${props => props.color};
  border: ${props => `1px solid ${props.bordercolor}`};
  background: ${props => props.background};;
  font-weight: bold;
  font-family: Vremena Grotesk;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-decoration: none;
  margin: 20px 0;

  :hover {
    text-decoration: ${props => `underline ${props.color}`};
  }

  @media (max-width: 767px) {
    min-width: 100%;
  }
`


PrimaryDownloadLink.defaultProps = {
  color: 'black',
  bordercolor: '#E23C30',
  background: 'transparent'
}

const SecondaryLink = ({ color, label, to }) => {
  const ariaLabel= `Link to ${to.replace(/\/|#/g, '')}`
  return (
    <StyledLink to={to} color={color} aria-label={ariaLabel}>
      {label}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 30px;
  color: ${props => props.color};
  background: transparent;
  font-weight: bold;
  font-family: Vremena Grotesk;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-decoration: none;
  margin: 20px 0;
  text-decoration: ${props => `underline ${props.color}`};
`

SecondaryLink.defaultProps = {
  width: 200,
  color: 'black',
}

const SecondaryOutLink = ({ color, label, to }) => {
  const ariaLabel= `External link to ${to.replace(/\/|#/g, '')}`
  return (
    <StyledOutLink href={to} color={color} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
      {label}
    </StyledOutLink>
  )
}

const StyledOutLink = styled.a`
  display: flex;
  align-items: center;
  height: 30px;
  color: ${props => props.color};
  background: transparent;
  font-weight: bold;
  font-family: Vremena Grotesk;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-decoration: none;
  margin: 10px 0;
  text-decoration: ${props => `underline ${props.color}`};
`

SecondaryOutLink.defaultProps = {
  width: 200,
  color: 'black',
}

export { PrimaryButton, PrimaryLink, PrimaryDownloadLink, SecondaryLink, SecondaryOutLink }
