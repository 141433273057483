import React from "react"
import styled from 'styled-components'

const Checkbox = ({ text, value, onChange, required }) => {
  return(
    <label className="checkboxContainer" style={{ marginTop: 4 }}>
      <Body>{text}</Body>
      <input
        type="checkbox"
        name="gdprCheckbox"
        aria-label="Accept GDPR"
        required
        checked={value}
        onChange={onChange}
      />
      <span className="checkmark" style={{ marginTop: 4 }}></span>
    </label>
  );
}

export default Checkbox;

const Body = styled.div`
  font-weight: Regular;
  font-family: Noah;
  line-height: 24px;
  font-size: 15px;
  color: #FFFFFF;
  line-height: normal;
`
