import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '../components/footer'
import Header from '../components/header'
import Section from '../components/sectionContainer'
import { PrimaryButton, PrimaryLink, SecondaryLink, PrimaryDownloadLink } from '../components/button'
import Climbing from '../components/images/climbing'
import Testimonials from '../components/testimonials'
import Craft from '../components/images/craft'
import Athlete from '../components/images/athlete'
import Call from '../components/images/call'
import EmailForm from '../components/emailForm'
import SectionTitle from '../components/section-title'
import { H1, H2, H3 } from '../components/headings'

import "normalize.css"

import One from '../assets/icons/one.svg';
import Two from '../assets/icons/two.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Appes - Meant to evolve" />
    <Helmet>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <script
        src="https://assets.calendly.com/assets/external/widget.js"
        async
        type="application/javascript"
      />
    </Helmet>
    <Header id="header" />
    <Section id="cover" style={{ marginTop: 50, backgroundColor: "#F2F2F2" }}>
      <CoverContainer>
        <CoverLeft>
          <H1GoingUp>Bring your app idea to life</H1GoingUp>
          <p>
            Whether you’re starting from scratch or looking to add new functionality
            to an existing app - Appes has the skills to bring your idea from the
            drawing board to reality.
          </p>
          <Link to="/#gain" aria-label="Scroll to next section">
            ↓
          </Link>
        </CoverLeft>
        <CoverRight>
          <ClimbingContainer>
            <Climbing />
          </ClimbingContainer>
        </CoverRight>
        <CoverTriangle />
      </CoverContainer>
    </Section>
    <Section id="gain">
      <GainContainer>
        <H2>Asking the right questions</H2>
        <p>
          What is the problem you are trying to solve? What’s the best way to
          approach it? Should you go for a web-based app, mobile, or both?
          What’s the most reliable tech to support it? How will you scale if needed?
        </p>
        <p>
          We guide you through the planning stage, identify the key requirements
          and match them to solutions.
        </p>
        <PrimaryLink label="Sounds Good?→" to="/#contact" />
      </GainContainer>
    </Section>
    <Section id="about">
      <AboutContainer>
        <AboutLeft>
          <AboutRectangle />
          <AthleteContainer>
            <Athlete />
          </AthleteContainer>
        </AboutLeft>
        <AboutRight>
          <SectionTitle>
            <span>Who we are</span>
          </SectionTitle>
          <div style={{ maxWidth: 475 }}>
            <H2>
              Appes = <Underline>apps</Underline> plus <Underline>e</Underline>volution.
            </H2>
          </div>
          <div style={{ maxWidth: 500 }}>
            <p>
              We’re a curious bunch of techies, designers, and thinkers who delight in
              creating innovative solutions to novel problems. With each project, we
              look beyond the present-day requirement to craft apps that will evolve
              to meet future challenges.
            </p>
          </div>
          <PrimaryLink label="Team & Manifesto →" to="/about" />
        </AboutRight>
      </AboutContainer>
    </Section>
    <Section id="cases">
      <CasesContainer>
        <H2>Ideas we’ve helped to evolve</H2>
        <CardsContainer>
          <CaseCard>
            <p className="name">Babele</p>
            <p className="title">
              Innovation platform for accelerators and organizations
            </p>
            <p className="description">
              We helped Babele improve user experience and increase user retention by
              optimizing the design and performance of their social innovation platform.
            </p>
            <SecondaryLink label="More →" to="/babele" />
          </CaseCard>
          <CaseCard>
            <p className="name">Velimo Hotel</p>
            <p className="title">Customizable guest services app</p>
            <p className="description">
              We created an intuitive mobile and smart TV app to deliver in-room content,
              mobile check-in, service requests, staff chat, smart QR door unlock, mood
              lighting control, and more.
            </p>
            <SecondaryLink label="More →" to="/velimo-hotel-whitelabel" />
          </CaseCard>
          <CaseCard>
            <p className="name">Wain Logistics</p>
            <p className="title">Deliver items to the client within an hour</p>
            <p className="description">
              We architected a comprehensive app to handle ordering, real-time delivery
              tracking, identifying the optimal shipping provider, and integration with partner vendors.
            </p>
            <SecondaryLink label="More →" to="/wain" />
          </CaseCard>
        </CardsContainer>
      </CasesContainer>
    </Section>
    <Section id="process">
      <ProcessContainer>
        <SectionTitle>
          <span>Our process</span>
        </SectionTitle>
        <ProcessTriangle />
        <CraftContainer>
          <Craft />
        </CraftContainer>
        <WorkProcessesContainer>
          <WorkProcesses>
            <WorkProcess>
              <One style={{ height: 140, width: 180, marginBottom: 30 }} />
              <ol>
                <li>Analyze the problem</li>
                <li>Evaluate solutions</li>
                <li>Make recommendations</li>
              </ol>
              <H2>Plan</H2>
              <p>
                Collaboration is the key to our process - we take the time to discuss
                and analyze your ideas until we’re confident we understand exactly what
                you need. Then, we look at the problem from all angles, proposing
                a range of solutions and identifying the approach which best fits
                your unique needs and future plans.
              </p>
              <p>
                Sometimes the best solution might not be to work with us - we’ll
                always be honest and steer you in the right direction.
              </p>
            </WorkProcess>
            <WorkProcess>
              <Two style={{ height: 140, width: 190, marginBottom: 30 }} />
              <ol>
                <li>Research and design</li>
                <li>Develop and test</li>
                <li>Deploy and maintain</li>
              </ol>
              <H2>Act</H2>
              <p>
                Once we’ve chosen the solution, we’ll start on the practical decisions:
                system architecture, technologies, scalability, costs, etc. With a defined
                project in place, we begin an iterative development process, keeping you
                in the loop throughout. When the final product is complete, we support
                you through testing, launch, and lifetime maintenance.
              </p>
            </WorkProcess>
          </WorkProcesses>
          <H3>
            Need more detail? Download our deck of services and capabilities.
          </H3>
          <PrimaryDownloadLink
            width={230}
            href="appes_services_and_apabilities.pdf"
            label="Our Tech & Capabilities ↓"
          />
        </WorkProcessesContainer>
      </ProcessContainer>
    </Section>
    <Section id="testimonials">
      <TestimonialsContainer>
        <SectionTitle>
          <span style={{ marginLeft: 80 }}>People we’ve worked with</span>
        </SectionTitle>
        <Clients>
          <People>
            <H2>
              Find out what our clients actually think about Appes
            </H2>
          </People>
          <Testimonials />
        </Clients>
      </TestimonialsContainer>
    </Section>
    <Section id="contact">
      <ContactContainer>
        <H2GoingUp>So, do you have an idea you want to bring to life?</H2GoingUp>
        <FormAndSchedule>
          <FormContainer>
            <SectionTitle style={{ borderColor: "white" }}>
              <span style={{ color: "white" }}>Send us a message</span>
            </SectionTitle>
            <EmailForm />
          </FormContainer>
          <ScheduleContainer>
            <Schedule>
              <SectionTitle style={{ borderColor: "white" }}>
                <span style={{ color: "white" }}>Schedule a call</span>
              </SectionTitle>
              <p style={{ fontSize: 22, color: "white" }}>
                Pick a time to chat with one of our team about your current tech challenges,
                your plans for the future, and your ideal app. No strings attached.
              </p>
              <PrimaryButton
                onClick={() => {
                  window.Calendly.initPopupWidget({
                    url: "https://calendly.com/appes/30min",
                  });
                  return false;
                }}
                type="Submit"
                label="Schedule →"
                color="white"
                bordercolor="white"
              />
            </Schedule>
            <CallContainer>
              <Call />
            </CallContainer>
          </ScheduleContainer>
        </FormAndSchedule>
      </ContactContainer>
    </Section>
    <Footer />
  </Layout>
);

const Underline = styled.span`
  text-decoration: underline;
`

const CoverContainer = styled.div`
  display: flex;
  position: relative;
  background: #F2F2F2;
  margin-top: 50px;
  padding: 0 60px;
  min-height: 643px;

  @media (max-width: 1336px) {
    width: 100%;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    background: #FFF;
    margin-top: 0;
    padding: 0 20px;

    p {
      text-align: left;
      font: Regular 22px/26px Noah;
      letter-spacing: -0.55px;
      color: #000000;
      opacity: 1;
    }
  }
`

const CoverLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;

  p {
    text-align: left;
    font-weight: Regular;
    font-family: Noah;
    line-height: 26px;
    font-size: 20px;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
    width: 400px;
  }

  a {
    text-align: left;
    font-size: 40px;
    letter-spacing: -1.13px;
    color: #000000;
    opacity: 1;
    text-decoration: none;
    width: 50px;

    :hover {
      color: #E23C30;
    }
  }

  @media (max-width: 1336px) {
    min-height: 300px;
    //padding: 20px 0 250px 0;

    p {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding: 0px;
  }
`

const H1GoingUp = styled(H1)`
  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const CoverRight = styled.div`
  flex: 1;
  z-index: 1;
  position: relative;

  @media (max-width: 767px) {
    min-height: 350px;
  }
`

const ClimbingContainer = styled.div`
  position: absolute;
  bottom: -27px;
  width: 600px;

  .gatsby-image-wrapper {
    position: absolute !important;
    bottom: -50px;
    left: 0;
    right: 0;
  }

  @media (max-width: 1336px) {
    right: -20px;
    width: 100%;

    .gatsby-image-wrapper {
      bottom: -30px;
    }
  }

  @media (max-width: 767px) {
    width: 300px;
    right: 0;

    .gatsby-image-wrapper {
      bottom: -11px;
    }
  }
`

const CoverTriangle = styled.div`
  width: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 0 560px 950px;
  border-color: transparent transparent #E23C30 transparent;

  @media (max-width: 1336px) {
    border-width: 0 0 360px 750px;
  }

  @media (max-width: 767px) {
    border-width: 0 0 360px 100vw;
  }
`

const GainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 470px;
  padding-top: 70px;

  p {
    text-align: center;
    font-weight: Regular;
    font-family: Noah;
    line-height: 26px;
    font-size: 20px;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
    margin-bottom: 1px;
    margin: 0 150px;
  }

  @media (max-width: 1336px) {
    margin: 0 37px 0 37px;
  }

  @media (max-width: 767px) {
    margin: 0 37px 40px 37px;
    min-height: 0;

    h2 {
      font-weight: Bold;
      font-family: Vremena Grotesk;
      line-height: 35px;
      font-size: 35px;
    }

    p {
      text-align: left;
      margin: 0 20px;
    }
  }
`

const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 570px;
  margin-top: 100px;
  background: #F2F2F2;

  @media (max-width: 1336px) {
    min-height: 470px;
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    margin-bottom: 50px;
    margin-top: 0;
  }
`

const AboutLeft = styled.div`
  flex: 1;
  min-height: inherit;
  position: relative;

  @media (max-width: 1336px) {
    min-width: 430px;
  }

  @media (max-width: 767px) {
    min-width: 0;
    padding-top: 50px;
    min-height: 300px;
  }
`

const AboutRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-height: inherit;
  padding: 0 60px 0 50px;

  p {
    letter-spacing: -0.45px;
    text-align: left;
    font-weight: Regular;
    font-family: Noah;
    line-height: 23px;
    font-size: 18px;
    @media (max-width: 1336px) {
      margin: 0;
    }
  }

  @media (max-width: 1336px) {
    min-height: inherit;
    padding: 0 20px 0 0;
  }

  @media (max-width: 767px) {
    padding: 0 37px 0 37px;
  }
`

const AthleteContainer = styled.div`
  width: 600px;
  position: absolute;
  bottom: 0;
  left: 70px;

  @media (max-width: 1336px) {
    left: -25px;
    width: 480px;
  }

  @media (max-width: 767px) {
    left: 0;
    width: 310px;
  }
`;

const AboutRectangle = styled.div`
  width: 400px;
  min-height: inherit;
  background-color: #E23C30;

  @media (max-width: 1336px) {
    max-width: 240px;
  }

  @media (max-width: 767px) {
    max-width: 170px;
  }
`

const CasesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 900px;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    H2 {
      padding: 0 20px 0 20px;
      text-align: center;
    }
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 0;
  }
`

const CaseCard = styled.div`
  max-width: 310px;
  flex: 1;
  min-height: 444px;
  border: 1px solid #E23C30;
  background: transparent;
  margin: 0 20px;
  padding: 20px;

  p.name {
    text-align: left;
    font: Bold 14px/24px Vremena Grotesk;
    letter-spacing: -0.35px;
    color: #000000;
    opacity: 1;
  }

  p.title {
    text-align: left;
    font: Bold 28px/34px Vremena Grotesk;
    letter-spacing: -0.7px;
    color: #000000;
    opacity: 1;
  }

  p.description {
    font-weight: Regular;
    font-family: Noah;
    line-height: 24px;
    font-size: 15px;
    text-align: left;
    letter-spacing: -0.38px;
    color: #000000;
    opacity: 1;
  }

  :hover {
    background-color: #E23C30
  }

  :hover p {
    color: white;
  }

  :hover a {
    color: white;
    text-decoration-color: white;
  }

  @media (max-width: 1336px) {
    padding: 20px 10px;
    margin: 0 10px;
  }

  @media (max-width: 767px) {
    max-height: inherit;
    max-width: 500px;
    min-height: inherit;
    margin: 20px;
    padding: 20px;
  }
`

const ProcessContainer = styled.div`
  padding: 0 60px;
  position: relative;
  min-height: 970px;
  background: #F2F2F2;

  li {
    text-align: left;
    font: Bold 18px/22px Vremena Grotesk;
    letter-spacing: -0.5px;
    color: #000000;
    opacity: 1;
    margin: 5px 0;

  }

  h2 {
    color: #E23C30;
  }

  p {
    text-align: left;
    font-weight: Regular;
    font-family: Noah;
    line-height: 24px;
    font-size: 15px;
    letter-spacing: -0.38px;
    color: #000000;
    opacity: 1;
  }

  span {
    margin-left: 90px;

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  @media (max-width: 1336px) {
    height: 1200px;
  }

  @media (max-width: 767px) {
    min-height: 1850px;
    padding: 0 30px;
  }
`

const CraftContainer = styled.div`
  width: 670px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 1336px) {
    width: 470px;
  }

  @media (max-width: 767px) {
    width: 390px;
  }
`

const ProcessTriangle = styled.div`
  width: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 0 748px 670px;
  border-color:
    transparent transparent #E23C30 transparent;

  @media (max-width: 1336px) {
    border-width: 0 0 525px 469px;
  }

  @media (max-width: 767px) {
    border-width: 0 0 435px 390px;
  }
`

const WorkProcessesContainer = styled.div`
  margin-left: 80px;
  max-width: 620px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1336px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

const WorkProcesses = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    min-width: inherit;
  }
`

const WorkProcess = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;

  @media (max-width: 767px) {
    margin-top: 40px;
  }
`

const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 900px;
  padding: 100px 60px;

  @media (max-width: 1336px) {
    padding: 50px 38px;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    padding: 50px 38px;
    margin-bottom: 0;
  }
`

const Clients = styled.div`
  display: flex;
  flex-direction: row;
  height: inherit;
  align-items: center;
  padding: 0 80px;
  justify-content: space-between;

  @media (max-width: 1336px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    min-height: 400px;
  }
`
const People = styled.div`
  max-width: 450px;
`

const ContactContainer = styled.div`
  padding: 50px 160px;
  min-height: 900px;
  margin-bottom: 200px;
  background-color: #E23C30;

  @media (max-width: 1336px) {
    padding: 30px 38px;
    margin-bottom: 0px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
  }
`


const FormAndSchedule = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 1336px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const FormContainer = styled.div`
  max-width: 550px;
  min-width: 232px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1336px) {
    max-width: 100%;
  }
`

const CallContainer = styled.div`
  width: 380px;
  position: absolute;
  bottom: 31px;
  right: 0;

  @media (max-width: 1336px) {
    width: 280px;
  }

  @media (max-width: 767px) {
    position: relative;
    bottom: -30px;
    left: 1vw;
  }
`

const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1336px) {
    padding-right: 330px;
  }

  @media (max-width: 767px) {
    padding-right: 0;
  }
`

const H2GoingUp = styled.h2`
  text-align: left;
  font: Bold 72px/55px Vremena Grotesk;
  letter-spacing: -1.8px;
  color: #FFFFFF;
  opacity: 1;

  @media (max-width: 1336px) {
    font-size: 55px;
    letter-spacing: -1.38px;
  }

  @media (max-width: 767px) {
    font-size: 50px;
    text-align: center;
    vertical-align: middle;
  }
`

const ScheduleContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1336px) {
    flex-direction: row;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    max-width: 100%;
  }
`

export default IndexPage
